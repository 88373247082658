import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/reset.css"
import "../styles/global.scss"
import SiteHeader from "../components/site-header"
import SiteFooter from "../components/site-footer"

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          youtubeUrl
        }
      }
    }
  `)
  const meta = data.site.siteMetadata

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', // key for sticky footer
    }}>
      <SiteHeader title={meta.title} />
      <main style={{ flex: 1 }}>{children}</main>
      <SiteFooter
        title={meta.title}
        description={meta.description}
        youtubeUrl={meta.youtubeUrl}
      />
    </div>
  )
}
