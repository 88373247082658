import React from "react"
import { Link } from "gatsby"
import st from "./site-footer.module.scss"

const SiteFooter = props => (
  <footer className={st.footer}>
    <div className={st.container}>
      <h1 className={st.siteTitle}>
        {props.title} — {props.description}
      </h1>
      <div className={st.navBottom}>
        <ul className={st.ul}>
          <li>
            <Link to="/fashion">Fashion</Link>
          </li>
          <li>
            <Link to="/review">Review</Link>
          </li>
          {/* <li>
            <Link to="/about">About</Link>
          </li> */}
          <li>
            <a
              href={props.youtubeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default SiteFooter
