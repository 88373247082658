import React from "react"
import { Link } from "gatsby"
import st from "./site-header.module.scss"

// can't use a better useStaticQuery...

const SiteHeader = props => (
  <header className={st.header}>
    <div className={st.container}>
      <h1 className={st.siteTitle}>
        <Link to="/">{props.title}</Link>
      </h1>
      <nav className={st.navMain}>
        <ul className={st.ul}>
          <li className={st.li}>
            <Link to="/">Home</Link>
          </li>
          <li className={st.li}>
            <Link to="/fashion">Fashion</Link>
          </li>
          <li className={st.li}>
            <Link to="/review">Review</Link>
          </li>
          {/* <li className={st.li}>
            <input type="text" placeholder="Search" disabled />
          </li> */}
        </ul>
      </nav>
    </div>
  </header>
)

export default SiteHeader
