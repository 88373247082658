import React from "react"
import st from "./postGeneral-container.module.scss"
import PostGeneralCard from "../components/postGeneral-card"

const PostGeneralContainer = props => (
  <section className={st.articleContainer}>
    {props.posts.map(post => (
      <PostGeneralCard
        key={post.id}
        id={post.id}
        category={post.category}
        title={post.title}
        slug={`${post.category}/${post.slug}`}
        createdAt={post.createdAt}
        featured={post.featured}
        thumbnail={post.thumbnail}
      />
    ))}
  </section>
)

export default PostGeneralContainer
